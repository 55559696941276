<template>
  <div class="developing">
    <div>
      <img src="@/assets/images/common/time.png">
      <p>页面正在创建中，敬请期待~</p>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
       
    }
  },
  components: {
    
  },
  methods: {
    
  },
}
</script>
<style scoped lang="less">
  .developing{
    width: 100%;
    height: calc(100vh - 6.25rem);
    display: flex;
    justify-content: center;
    background-color: #F5F5F5;
    div{
      margin: 15.75rem 0;
      display: flex;
      flex-direction: column;
      img{
        margin: 0 auto;
      }
      p{
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
        margin-top: 2.25rem;
      }
    }
  }
</style>